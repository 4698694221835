import React from 'react';

const PlusIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
			<g data-name="Group 977" stroke="#00214d" strokeWidth="2">
				<g data-name="Rectangle 485" transform="rotate(180 30 30)" fill="#fff">
					<rect width="60" height="60" rx="30" stroke="none"/>
					<rect x="1" y="1" width="58" height="58" rx="29" fill="none"/>
				</g>
				<g data-name="Group 976" fill="none" strokeLinecap="round">
					<path data-name="Path 533" d="M29.997 16.269V43.73"/>
					<path data-name="Path 534" d="M43.731 29.998H16.27"/>
				</g>
			</g>
		</svg>
	);
};

export default PlusIcon;